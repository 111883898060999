<!--  -->
<template>
    <div class=''>
        <div>
    
            <quill-editor class="editor"
                        ref="myTextEditor"
                        v-model="content"
                        :options="editorOption"
                        @blur="onEditorBlur($event)"
                        @focus="onEditorFocus($event)"
                        @ready="onEditorReady($event)"
                        @change="onEditorChange($event)">
          </quill-editor>
          </div>
            <div class="herader">
                    <el-row :gutter="20">
                         <el-col :span="8"> </el-col>
            <el-tooltip
              class="item"
              effect="dark"
              content="返回活动协议"
              placement="top-start"
            >
              <el-button  type="primary" @click="backBtn">返 回 主 页</el-button>
            </el-tooltip>
            <el-col :span="8" > </el-col>
            <el-tooltip
              class="item"
              effect="dark"
              content="确认增加活动协议"
              placement="top-start"
              v-if="type==2"
            style="margin-left:10px;"
            >
              <el-button  type="primary" @click="addArea">确 认 增 加</el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改活动协议"
              placement="top-start"
              v-if="type==1"
                 style="margin-left:10px;"
            >
              <el-button  type="primary" @click="updSiteSer">确 认 修 改</el-button>
            </el-tooltip>
          </el-row>
          </div>
            <!-- 增加场地服务标题 -->
      <el-dialog
        :visible.sync="dialogFormVisible"
        @close="userClose(form)"
        :append-to-body="true"
        
      >
      <div slot="title" v-if="updataFalg" class="dialog-footer" >
          <div class="title" >修改协议条款</div>
        </div>
         <div slot="title" v-else-if="lookFalg" class="dialog-footer" >
          <div class="title" >查看协议条款</div>
        </div>
        <div slot="title" v-else class="dialog-footer" >
          <div class="title" >增加协议条款</div>
        </div>
        <div class="diaBox">
        <el-form :model="form" ref="ruleForm" >
          <el-form-item label="标题" :label-width="formLabelWidth" >
            <el-input  v-model="form.advertisement_title" placeholder="标题"  style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item label="排序" :label-width="formLabelWidth" >
            <el-input  v-model="form.sort" placeholder="请输入排序"  style="width: 200px"></el-input>
          </el-form-item>
                <el-form-item label="状态" :label-width="formLabelWidth">
             <template>
             <el-switch
    v-model="form.status"
    active-color="#13ce66"
    inactive-color="#ff4949">
  </el-switch>          
            </template>
          </el-form-item>
        </el-form>
         </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button v-if="addFlag" type="primary" @click="addArea1('ruleForm')"
            >添 加</el-button
          >
          <el-button v-else-if="lookFalg" type="primary" @click="dialogFormVisible = false"
            >确 认</el-button
          >
         <el-button v-else type="primary" @click="updSiteSer1('ruleForm')"
            >修 改</el-button
          >
        </div>
      </el-dialog> 
    </div>
    </template>
    
    <script>
    //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
    //例如：import 《组件名称》 from '《组件路径》';
    import {agreementAdd,agreementEdit} from "@/api/site/agreementList.js"
    export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
    //这里存放数据
    return {
               id:'',
               type:'',
               content: null,
                editorOption: {
                  modules: {
                    toolbar: [
                      ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                      ["blockquote", "code-block"], // 引用  代码块
                      [{ header: 1 }, { header: 2 }], // 1、2 级标题
                      [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
                      [{ script: "sub" }, { script: "super" }], // 上标/下标
                      [{ indent: "-1" }, { indent: "+1" }], // 缩进
                      // [{'direction': 'rtl'}],                         // 文本方向
                      [{ size: ["small", false, "large", "huge"] }], // 字体大小
                      [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                      [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                      [{ font: [] }], // 字体种类
                      [{ align: [] }], // 对齐方式
                      ["clean"], // 清除文本格式
                      ["link", "image", "video"] // 链接、图片、视频
                    ], //工具菜单栏配置
                  },
                  placeholder: '请在这里添加产品描述', //提示
                  readyOnly: false, //是否只读
                  theme: 'snow', //主题 snow/bubble
                  syntax: true, //语法检测
                },
                formLabelWidth: "100px",
                form:{
          content:'',//内容
          sort:'',//排序
          status:true,
          advertisement_title:''
        },
        advertisement_id:'',
         //控制弹窗--新增修改
         dialogFormVisible: false,
        addFlag:false,
        lookFalg:false,
        updataFalg:false,
    };
    },
    //监听属性 类似于data概念
    computed: {
             editor() {
                return this.$refs.myTextEditor.quillEditor;
              },
    },
    //监控data中的数据变化
    watch: {
      // dialogFormVisible(newVal, oldVal) {
      //   if (!newVal) {
      //     this.form = this.$options.data().form;
      //     this.updataFalg=false;
      //     this.lookFalg=false;
      //     this.addFlag=false;
      //   }
      // },
    },
    //方法集合
    methods: {
        backBtn(){
             this.$router.go(-1)
        },
          // 失去焦点
              onEditorBlur(editor) {},
              // 获得焦点
              onEditorFocus(editor) {},
              // 开始
              onEditorReady(editor) {},
              // 值发生变化
              onEditorChange(editor) {
                this.content = editor.html;
                console.log(editor);
              },
                     //增加活动协议
        addArea(){
            // console.log(this.content)
         
            this.addFlag=true;
            this.dialogFormVisible=true;
           
        },
        addArea1(){
          var datas={
            advertisement_type:1,
            advertisement_title:this.form.advertisement_title,
            advertisement_content:this.content,
            advertisement_sort:1,
                advertisement_status:this.form.status?1:0,
            }
          this.agreementAdd(datas);
        },
        updSiteSer(){
          this.updataFalg=true;
            this.dialogFormVisible=true;
           // console.log(this.content)
       
        },
        updSiteSer1(){
     var data={
              advertisement_id:this.advertisement_id,
              advertisement_type:1,
            advertisement_title:this.form.advertisement_title,
            advertisement_content:this.content,
            advertisement_sort:1,
            advertisement_status:this.form.status?1:0,
            }
           this.agreementEdit(data);
        },
                 //添加事件 退出弹窗
      userClose(e) {
        this.form = this.$options.data().form;
          this.updataFalg=false;
          this.lookFalg=false;
          this.addFlag=false;
      },
            /**------------网络请求函数------------- */
                        //活动协议增加
      async agreementAdd(datas){
            let res=await agreementAdd(datas);
                 if(res.data.status==1){                   
                 this.$message({
              message: res.data.message,
              type: "success",
            });
        this.dialogFormVisible=false;
        this.$router.go(-1);
          //  this.agreementList(data);
             }else{
                this.$message({
              message: res.data.message,
              type: "error",
            });
             }
      },
              //活动协议修改
      async agreementEdit(datas){
        console.log(datas)
            let res=await agreementEdit(datas);
                 if(res.data.status==1){                   
                 this.$message({
              message: res.data.message,
              type: "success",
            });
        //       var data = {
        //   page: this.getInfo.page_code,
        //   list_rows: this.getInfo.page_num,
        // };
        this.dialogFormVisible=false;
        this.$router.go(-1);
      //      this.agreementList(data);
             }else{
                this.$message({
              message: res.data.message,
              type: "error",
            });
             }
      },
    },
    beforeCreate() {}, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
      console.log(this.$route.params)
         this.content=this.$route.params.content;
         this.type=this.$route.params.type;
         this.advertisement_id=this.$route.params.id;
         this.form.advertisement_title=this.$route.params.advertisement_title
         this.form.sort=this.$route.params.sort;
    },
    beforeMount() {}, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
    
    },
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
    </script>
    <style>
    .herader{
     margin:50px 10px 10px 10px;
    }
    .editor {
        line-height: normal !important;
        height: 800px;
      }
      .ql-snow .ql-tooltip[data-mode=link]::before {
        content: "请输入链接地址:";
      }
      .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
          border-right: 0px;
          content: '保存';
          padding-right: 0px;
      }
     
      .ql-snow .ql-tooltip[data-mode=video]::before {
          content: "请输入视频地址:";
      }
     
      .ql-snow .ql-picker.ql-size .ql-picker-label::before,
      .ql-snow .ql-picker.ql-size .ql-picker-item::before {
        content: '14px';
      }
      .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
        content: '10px';
      }
      .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
        content: '18px';
      }
      .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
        content: '32px';
      }
     
      .ql-snow .ql-picker.ql-header .ql-picker-label::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item::before {
        content: '文本';
      }
      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
        content: '标题1';
      }
      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
        content: '标题2';
      }
      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
        content: '标题3';
      }
      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
        content: '标题4';
      }
      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
        content: '标题5';
      }
      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
        content: '标题6';
      }
     
      .ql-snow .ql-picker.ql-font .ql-picker-label::before,
      .ql-snow .ql-picker.ql-font .ql-picker-item::before {
        content: '标准字体';
      }
      .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
      .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
        content: '衬线字体';
      }
      .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
      .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
        content: '等宽字体';
      }
      .title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox {
  display: flex;
  justify-content: center;
}
.imgUrl {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 200px;
  overflow: hidden;
  text-align: center;
}
    </style>