import {post} from '@/utils/request'

//区域列表
export function agreementList(data) {
  return post('adminapi/advertisement_list',data)
}
//区域列表增加
export function agreementAdd(data) {
    return post('adminapi/advertisement_add',data)
  }
  //区域列表删除
export function agreementDel(data) {
    return post('adminapi/advertisement_delete',data)
  }
  //区域列表编辑
export function agreementEdit(data) {
    return post('adminapi/advertisement_update',data)
  }
  //区域列表查询
export function agreementInfo(data) {
    return post('admin/activity/agreement/info',data)
  }